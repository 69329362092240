.wrap-items{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
.raw-item-input{
    color: white;
    background-color: transparent;
    height: 70px;
    margin-left: 25px;
}