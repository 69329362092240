.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cursor {
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
  width: 20px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  padding: 12px;
  background-color: rgb(62, 62, 62);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 9999;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mods-price-create {
  width: 75px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
