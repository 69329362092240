.file-drop {
    background: #000;
    border: 1px dashed #c2c2c2;
    border-radius: 3px;
    text-align: center;
    padding: 36px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.file-drop:hover {
    background: #636f83;
    color: white;
    border: 1px dashed #333;
}
.delete-upload-file {
    cursor: pointer;
    color: #e55353;
}
/* .default-btn {
    margin-top: 30px;
    margin-bottom: 30px;
} */
