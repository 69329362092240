
.select-hotel {
    border-radius: 12px;
    width: 300px;
    height: 40px;
    margin-left: 4px;
  }
  .float-right {
    float: 'right'
  }

  .border-bottom{
    border-bottom: 1px solid #666666;
  }
  .margin-bottom-20 {
    margin-bottom: 20px;
  }
  .margin-top-150 {
    margin-top: 150px;
  }
  .margin-top-30 {
    margin-top: 30px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .margin-top-50 {
    margin-top: 50px;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-left-10 {
    margin-left: 10px;
  }
  .margin-left-auto {
    margin-left: auto;
  }
  .padding-top-10 {
    padding-top: 10px;
  }
  .color-orange{
    color: #ffa500;
    font-weight: 600;
    font-size: 22px;
  }

  .box-plus {
    width: 250px;
    height: 250px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 32px 24px;
    padding: 12px;
  }
  .box-plus-groups {
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 32px 24px;
    padding: 12px;
  }
  .sub-title-2 {
    font-size: 28px;
    margin-top: 30px;
    margin-left: 4px;
  }
  .width-300 {
    width: 150px;
  }
  .width-200 {
    width: 200px;
  }
  .flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-column-between-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-align-center {
    text-align: center;
  }