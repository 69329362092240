.loading {
    position: relative;
    text-align: center;
}

.loader-hidden {
    display: none !important;
}

.loader-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    opacity: 1;
    color: #0b2e13;
    transition: opacity 0.5s ease;
    z-index: 99999;
    display: flex;
    top: 0;
    left: 0;
}

.loader-container.loaded {
    opacity: 0;
}

.spinner {
    margin: 0 auto;
    width: 4rem;
    height: 4rem;
}

.spinner.spinner1 {
    border: 3px solid rgba(255, 255, 255, 0.25);
    border-top-color: #39AB9F;
    border-radius: 50%;
    -webkit-animation: rotation .8s ease infinite;
    animation: rotation .8s ease infinite;
}

.spinner.spinner2 {
    border: 3px solid transparent;
    border-top-color: #39AB9F;
    border-bottom-color: #39AB9F;
    border-radius: 50%;
    -webkit-animation: rotation .8s ease infinite;
    animation: rotation .8s ease infinite;
}

.spinner.spinner3 {
    border-top: 3px solid #39AB9F;
    border-right: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: rotation .8s linear infinite;
    animation: rotation .8s linear infinite;
}

.spinner.spinner4 {
    /*background: #39AB9F;*/
    -webkit-animation: flip 1.2s ease infinite;
    animation: flip 1.2s ease infinite;
}

.spinner.spinner5 {
    margin-top: 2rem;
    overflow: hidden;
    position: relative;
    height: .5rem;
    /*background: rgba(255, 255, 255, 0.25);*/
}

.spinner.spinner5::before {
    content: '';
    position: absolute;
    left: -130%;
    width: 100%;
    height: 100%;
    /*background: #39AB9F;*/
    -webkit-animation: progress 4s linear infinite;
    animation: progress 4s linear infinite;
}

.spinner.spinner6 {
    position: absolute;
    top: 45%;
    left: 50%;
    /*background: #fff;*/
    border-radius: 50%;
    -webkit-animation: pulse 1s ease-in-out infinite;
    animation: pulse 1s ease-in-out infinite;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
}

.spinner.spinner7 {
    position: relative;
}

.spinner.spinner7::before, .spinner.spinner7::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
}

.spinner.spinner7::before {
    background: #00837D;
    -webkit-animation: pulse2 2s ease-in-out infinite;
    animation: pulse2 2s ease-in-out infinite;
}

.spinner.spinner7::after {
    background: #00837D;
    -webkit-animation: pulse2 2s 1s ease-in-out infinite;
    animation: pulse2 2s 1s ease-in-out infinite;
}

.spinner.spinner8 {
    position: relative;
    -webkit-perspective: 200px;
    perspective: 200px;
}

.spinner.spinner8::before {
    display: block;
    content: '';
    width: 50%;
    height: 50%;
    background: #39AB9F;
    -webkit-animation: 2s flipWalker ease infinite;
    animation: 2s flipWalker ease infinite;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@keyframes flip {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@-webkit-keyframes progress {
    0% {
        left: -130%;
        background: #39AB9F;
    }
    50% {
        left: 130%;
        background: #39AB9F;
    }
    51% {
        background: rgba(255, 255, 255, 0.5);
    }
    100% {
        background: rgba(255, 255, 255, 0.5);
    }
}

@keyframes progress {
    0% {
        left: -130%;
        background: #39AB9F;
    }
    50% {
        left: 130%;
        background: #39AB9F;
    }
    51% {
        background: rgba(255, 255, 255, 0.5);
    }
    100% {
        background: rgba(255, 255, 255, 0.5);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes pulse2 {
    0%, 100% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

@keyframes pulse2 {
    0%, 100% {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes flipWalker {
    0% {
        -webkit-transform: translate(0, 0) rotateX(0) rotateY(0);
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }
    25% {
        -webkit-transform: translate(100%, 0) rotateX(0) rotateY(180deg);
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }
    50% {
        -webkit-transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }
    75% {
        -webkit-transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotateX(0) rotateY(360deg);
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}

@keyframes flipWalker {
    0% {
        -webkit-transform: translate(0, 0) rotateX(0) rotateY(0);
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }
    25% {
        -webkit-transform: translate(100%, 0) rotateX(0) rotateY(180deg);
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }
    50% {
        -webkit-transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }
    75% {
        -webkit-transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotateX(0) rotateY(360deg);
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}
