.chip {
    padding: 4px 10px;
    background: #0096fb!important;
    color:white;
}
  .optionListContainer{
      background: black!important;
  }
  .upload{
      margin-top:25px;
  }