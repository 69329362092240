/* .info{
    margin-bottom:53px;
} */
.divider .main-content header {
    z-index: 2
}

.inner-content {
    margin-left: 10px;
    overflow-y: 'scroll';
    height: auto;
  }