.edit-title{
    cursor: pointer;
}
.icon{
    cursor: pointer;
}
.is-active{
    border: 1px solid #1793c2
}
.tab-carousel {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
div.options-wrapper.active{
    background-color: rgba(255, 255, 255, 0.1);;
}
.section-name{
    background-color: transparent;
    color:white;
}
.link {
    cursor: pointer;
}
.active-icon {
    border: 3px solid #1793c2;
}

.inactive-btn {
    background-color: rgba(238, 238, 238, 0.1);
    border-radius: 121px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 500;
  }
.active-btn {
    background-color: #1793c2;
    border-radius: 121px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 500;
  }
.padding-vertical-20 {
    padding: 7px 10px;
}
.margin-top-20 {
    margin-top: 20px;
}