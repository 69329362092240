.active {
    background-color: #1793c2;
}
.divider .sidebar li {
    margin-bottom: 0px;
    padding: 12px;
}
.displayed {
    display: block;
}
.dissapear {
    display: none;
}
.rootCategory-category {
    cursor: pointer;
}
.padding-20 {
    padding: 20px;
}
